var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var lib_date_exports = {};
__export(lib_date_exports, {
  DateTime: () => DateTime,
  Datetime: () => Datetime,
  add: () => add,
  addDays: () => addDays,
  addWorkingDay: () => addWorkingDay,
  create: () => create,
  default: () => lib_date_default,
  format: () => gFormat,
  getString: () => getString,
  moment: () => import_moment.default
});
module.exports = __toCommonJS(lib_date_exports);
var import_moment = __toESM(require("moment"));
var import_lib_core = require("@bsgp/lib-core");
var import_lib_core2 = require("@bsgp/lib-core");
const defaultInvalidDate = "";
const locale = window.navigator.userLanguage || window.navigator.language;
const koLocale = {
  months: "1\uC6D4_2\uC6D4_3\uC6D4_4\uC6D4_5\uC6D4_6\uC6D4_7\uC6D4_8\uC6D4_9\uC6D4_10\uC6D4_11\uC6D4_12\uC6D4".split("_"),
  monthsShort: "1\uC6D4_2\uC6D4_3\uC6D4_4\uC6D4_5\uC6D4_6\uC6D4_7\uC6D4_8\uC6D4_9\uC6D4_10\uC6D4_11\uC6D4_12\uC6D4".split("_"),
  weekdays: "\uC77C\uC694\uC77C_\uC6D4\uC694\uC77C_\uD654\uC694\uC77C_\uC218\uC694\uC77C_\uBAA9\uC694\uC77C_\uAE08\uC694\uC77C_\uD1A0\uC694\uC77C".split("_"),
  weekdaysShort: "\uC77C_\uC6D4_\uD654_\uC218_\uBAA9_\uAE08_\uD1A0".split("_"),
  weekdaysMin: "\uC77C_\uC6D4_\uD654_\uC218_\uBAA9_\uAE08_\uD1A0".split("_"),
  longDateFormat: {
    LT: "A h:mm",
    LTS: "A h:mm:ss",
    L: "YYYY.MM.DD.",
    LL: "YYYY\uB144 MMMM D\uC77C",
    LLL: "YYYY\uB144 MMMM D\uC77C A h:mm",
    LLLL: "YYYY\uB144 MMMM D\uC77C dddd A h:mm",
    l: "YYYY.MM.DD.",
    ll: "YYYY\uB144 MMMM D\uC77C",
    lll: "YYYY\uB144 MMMM D\uC77C A h:mm",
    llll: "YYYY\uB144 MMMM D\uC77C dddd A h:mm"
  },
  calendar: {
    sameDay: "\uC624\uB298 LT",
    nextDay: "\uB0B4\uC77C LT",
    nextWeek: "dddd LT",
    lastDay: "\uC5B4\uC81C LT",
    lastWeek: "\uC9C0\uB09C\uC8FC dddd LT",
    sameElse: "L"
  },
  relativeTime: {
    future: "%s \uD6C4",
    past: "%s \uC804",
    s: "\uBA87 \uCD08",
    ss: "%d\uCD08",
    m: "1\uBD84",
    mm: "%d\uBD84",
    h: "\uD55C \uC2DC\uAC04",
    hh: "%d\uC2DC\uAC04",
    d: "\uD558\uB8E8",
    dd: "%d\uC77C",
    M: "\uD55C \uB2EC",
    MM: "%d\uB2EC",
    y: "\uC77C \uB144",
    yy: "%d\uB144"
  },
  dayOfMonthOrdinalParse: /\d{1,2}(일|월|주)/,
  ordinal: function(number, period) {
    switch (period) {
      case "d":
      case "D":
      case "DDD":
        return number + "\uC77C";
      case "M":
        return number + "\uC6D4";
      case "w":
      case "W":
        return number + "\uC8FC";
      default:
        return number;
    }
  },
  meridiemParse: /오전|오후/,
  isPM: function(token) {
    return token === "\uC624\uD6C4";
  },
  meridiem: function(hour, minute, isUpper) {
    return hour < 12 ? "\uC624\uC804" : "\uC624\uD6C4";
  }
};
import_moment.default.locale(locale, __spreadValues({
  invalidDate: defaultInvalidDate
}, locale === "ko" ? koLocale : {}));
import_moment.default.updateLocale(locale, { invalidDate: defaultInvalidDate });
if (process.env.NODE_ENV === "production") {
  console.log("locale:", locale, "moment.locale():", import_moment.default.locale());
}
const defaultTimeZone = "+09:00";
const defaultFormat = {
  YEAR: "YYYY",
  MONTH: "MM",
  DATE: "DD",
  HOUR: "HH",
  MINUTE: "mm",
  SECOND: "ss"
};
const gFormat = __spreadValues({}, defaultFormat);
gFormat.ui5 = __spreadProps(__spreadValues({}, defaultFormat), {
  YEAR: "yyyy",
  DATE: "dd"
});
gFormat.ui5.date = [gFormat.ui5.YEAR, gFormat.ui5.MONTH, gFormat.ui5.DATE].join(
  "-"
);
gFormat.ui5.time = [
  gFormat.ui5.HOUR,
  gFormat.ui5.MINUTE,
  gFormat.ui5.SECOND
].join(":");
gFormat.ui5.datetime = [gFormat.ui5.date, gFormat.ui5.time].join(" ");
gFormat.A1Date = [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join("");
gFormat.A1Time = [gFormat.HOUR, gFormat.MINUTE, gFormat.SECOND].join("");
gFormat.default = {
  date: [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join("-"),
  time: [gFormat.HOUR, gFormat.MINUTE, gFormat.SECOND].join(":")
};
gFormat.default.datetime = [gFormat.default.date, gFormat.default.time].join(
  " "
);
gFormat.a1 = {
  date: gFormat.A1Date,
  time: gFormat.A1Time
};
gFormat.date = { ui5: {}, js: {} };
gFormat.date.ui5.dash = gFormat.ui5.date;
gFormat.date.ui5.none = [
  gFormat.ui5.YEAR,
  gFormat.ui5.MONTH,
  gFormat.ui5.DATE
].join("");
gFormat.date.ui5.dot = [
  gFormat.ui5.YEAR,
  gFormat.ui5.MONTH,
  gFormat.ui5.DATE
].join(".");
gFormat.date.js.dash = [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join("-");
gFormat.date.js.none = [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join("");
gFormat.date.js.dot = [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join(".");
gFormat.time = { ui5: {}, js: {} };
gFormat.time.ui5.colon = gFormat.ui5.time;
gFormat.time.ui5.none = [
  gFormat.ui5.HOUR,
  gFormat.ui5.MINUTE,
  gFormat.ui5.SECOND
].join("");
gFormat.date.js.colon = [gFormat.HOUR, gFormat.MINUTE, gFormat.SECOND].join(
  ":"
);
gFormat.date.js.none = [gFormat.HOUR, gFormat.MINUTE, gFormat.SECOND].join("");
function getString(dateObj, presetTime, option = {}) {
  if ((0, import_lib_core2.isObject)(presetTime)) {
    option = presetTime;
  }
  const _presetTime = (0, import_lib_core2.isString)(presetTime) ? presetTime : option.presetTime;
  const tz = (0, import_lib_core.defined)(option.tz, defaultTimeZone);
  const outputAsISO = (0, import_lib_core.defined)(option.outputAsISO, false);
  const toUTC = (0, import_lib_core.defined)(option.toUTC, false);
  const format = option.format;
  const zoneParsed = (0, import_moment.default)(dateObj).utcOffset(tz);
  if (["b", "begin"].findIndex((itm) => itm === _presetTime) >= 0) {
    zoneParsed.startOf("date");
  } else if (["e", "end"].findIndex((itm) => itm === _presetTime) >= 0) {
    zoneParsed.endOf("date");
  }
  const dateUTC = toUTC === true ? zoneParsed.utc() : zoneParsed;
  if (outputAsISO === true) {
    return dateUTC.format(
      (0, import_lib_core.defined)(format, `${gFormat.default.date}T${gFormat.default.time}.SSS[Z]`)
    );
  } else {
    return dateUTC.format(
      (0, import_lib_core.defined)(format, `${gFormat.default.date}T${gFormat.default.time}[Z]`)
    );
  }
}
function create(inDate, options = {}) {
  const [date, timezone, format] = ((sapDate = inDate) => {
    const timezone2 = (0, import_lib_core.defined)(options.timezone, defaultTimeZone);
    const format2 = (0, import_lib_core.defined)(options.format, gFormat.default.datetime);
    if (sapDate instanceof Date) {
      return [sapDate, timezone2, format2];
    }
    if ((0, import_lib_core2.isFalsy)(sapDate)) {
      return [defaultInvalidDate, timezone2, format2];
    }
    if ((0, import_lib_core2.isString)(sapDate)) {
      if (/^\d{1,}$/.test(sapDate)) {
        if (options.format) {
          return [(0, import_moment.default)(sapDate, format2).toDate(), timezone2, format2];
        }
        return [(0, import_moment.default)(parseInt(sapDate, 10)).toDate(), timezone2, format2];
      }
      const numberString = sapDate.replace(/^\/Date\(/, "").replace(")/", "");
      if (/^\d{1,}$/.test(numberString)) {
        return [new Date(parseInt(numberString, 10)), timezone2, format2];
      }
      if (options.format === void 0) {
        return [(0, import_moment.default)(sapDate).toDate(), timezone2, format2];
      }
    }
    if ((0, import_lib_core2.isNumber)(sapDate)) {
      return [(0, import_moment.default)(sapDate).toDate(), timezone2, format2];
    }
    return [(0, import_moment.default)(sapDate, format2).toDate(), timezone2, format2];
  })();
  const now = (0, import_moment.default)();
  const result = {
    date,
    timezone,
    fromNow: (0, import_moment.default)(date).from(now),
    format,
    utcOffset: now.utcOffset(),
    changeFormat: function($format, options2 = {}) {
      const toUTC = (0, import_lib_core.defined)(options2.toUTC, false);
      const { presetTime } = options2;
      this.format = $format;
      this.formatted = getString(this.date, {
        format: this.format,
        tz: this.timezone,
        toUTC,
        presetTime
      });
      return this.formatted;
    },
    getISOString: function(option = {}) {
      const begin = (0, import_lib_core.defined)(option.begin, false);
      const end = (0, import_lib_core.defined)(option.end, false);
      const toUTC = (0, import_lib_core.defined)(option.toUTC, false);
      if (begin === true) {
        return getString(this.date, "begin", {
          tz: this.timezone,
          outputAsISO: true,
          toUTC
        });
      } else if (end === true) {
        return getString(this.date, "end", {
          tz: this.timezone,
          outputAsISO: true,
          toUTC
        });
      }
      return getString(this.date, {
        tz: this.timezone,
        outputAsISO: true,
        toUTC
      });
    },
    formatted: (0, import_moment.default)(date).utcOffset(timezone).format(format),
    utcString: getString(date, { tz: timezone, toUTC: true })
  };
  return result;
}
function addWorkingDay(date, days) {
  const mDate = (0, import_moment.default)(date.date).utcOffset(date.timezone);
  const newDays = days + Math.floor((mDate.day() + days - 1) / 5) * 2;
  if (days >= 5) {
  }
  mDate.add(newDays, "days");
  return create(mDate.toDate(), { timezone: date.timezone });
}
function add(date, number, pOption, spare) {
  let option = {};
  if ((0, import_lib_core2.isString)(pOption)) {
    option = spare || {};
    option.unit = pOption;
  } else {
    option = pOption || {};
  }
  const wantWorkDay = (0, import_lib_core.defined)(option.wantWorkDay, false);
  const unit = (0, import_lib_core.defined)(option.unit, "days");
  const mDate = (0, import_moment.default)(date);
  mDate.add(number, unit);
  if (wantWorkDay === true) {
    if (mDate.day() === 0) {
      mDate.add(1, unit);
    } else if (mDate.day() === 6) {
      mDate.add(2, unit);
    }
  }
  return create(mDate.toDate(), { timezone: mDate.utcOffset() });
}
function distance(fromDate, type, options = {}) {
  const { firstDayOf, to } = options;
  const disType = import_moment.default.normalizeUnits(type);
  const firstDayOfTo = import_moment.default.normalizeUnits(firstDayOf);
  const fDate = (0, import_moment.default)(fromDate);
  fDate.startOf(disType);
  const tDate = to ? (0, import_moment.default)(to) : (0, import_moment.default)();
  if (firstDayOfTo) {
    tDate.startOf(firstDayOfTo);
    const tDateCloned = tDate.clone().startOf(disType);
    if (tDateCloned.isBefore(tDate)) {
      tDate.startOf(disType);
    }
  } else {
    tDate.startOf(disType);
  }
  const result = fDate.diff(tDate, type, true);
  if (Number.isNaN(result)) {
    return result;
  }
  return Math.abs(result) + 1;
}
create.format = gFormat;
const addDays = add;
const Datetime = create;
const DateTime = create;
const defaultExports = {
  Datetime,
  DateTime,
  create,
  addWorkingDay,
  add,
  addDays,
  distance,
  getString,
  moment: import_moment.default,
  format: gFormat
};
var lib_date_default = defaultExports;
